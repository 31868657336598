import {
    // ADD_CLIENT,
    // ADD_CLIENT_SUCCESS,
    // ADD_CLIENT_ERROR,

    BEGIN_LIST_CLIENTS,
    LIST_CLIENTS_SUCCESS,
    LIST_CLIENTS_ERROR,

    BEGIN_POST_CLIENT,
    POST_CLIENT_SUCCESS,
    POST_CLIENT_ERROR,

    TRYING_DELETE_CLIENTE,
    CLIENTE_DELETED_SUCCESS,
    CLIENTE_DELETED_ERROR,

    SEARCH_CLIENT,
    SEARCH_CLIENT_SUCCESS,
    SEARCH_CLIENT_ERROR,

    BEGIN_UPDATE_CLIENT,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_ERROR,

    UPLOAD_MASIVE_EXCEL_TRY,
    UPLOAD_MASIVE_EXCEL_SUCCESS,
    UPLOAD_MASIVE_EXCEL_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function borrarCliente( cliente_id ){
    return async ( dispatch ) => {
        dispatch( intentandoBorrarCliente() );
        try {
            const respuesta = await clienteAxios.delete(`/clients/${cliente_id}`);
            console.log('respuesta post client',respuesta)
            dispatch( clienteBorradoConExito() )

        } catch (error) {
            console.log(error)

            dispatch( clienteBorradoError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}   
export function crearNuevoCliente( cliente ){
    return async ( dispatch ) => {
        dispatch( haciendoPostNuevoCliente() );
        try {
            const respuesta = await clienteAxios.post('/clients',cliente);
            // console.log('respuesta post client',respuesta)
            dispatch( postNuevoClienteExitoso(respuesta.data) )

        } catch (error) {
            console.log('error.response',error.response.data)

            

            if(error.response.data && error.response.data.message){
                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.response.data.message
                })

                // dispatch( postNuevoClienteError(error.response.data.message) );
            }else{

                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error, intenta de nuevo'
                })
                dispatch( postNuevoClienteError("Hubo un error, intenta de nuevo") );
            }

            
        }
    }
}
export function obtenerClientesAction({paginate,stringparams}){
    return async ( dispatch ) => {
        dispatch( descargarClientes() );
        try {
            let respuesta;
            if(paginate ==  true){
                respuesta = await clienteAxios.get(`/clients?paginate=true&${stringparams}`);
            }else{
                respuesta = await clienteAxios.get('/clients');
            }
            console.log('respuesta.data',respuesta.data)
            dispatch( descargaClienteExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaClienteError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

export function actualizarCliente( cliente ){
    return async ( dispatch ) => {
        dispatch( comienzoActualizarCliente() );
        try {
            const respuesta = await clienteAxios.put(`/clients/${cliente.id}`,cliente);

            dispatch( actualizarClienteExitoso(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Actualización realizada con éxito!.'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarClienteError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }       
}
const comienzoActualizarCliente = () => ({
    type:BEGIN_UPDATE_CLIENT,
    payload:true
})
const actualizarClienteExitoso = ( cliente ) => ({
    type:UPDATE_CLIENT_SUCCESS,
    payload:cliente
})
const actualizarClienteError = ( error ) => ({
    type:UPDATE_CLIENT_ERROR,
    payload:error
})

export function obtenerCliente( cliente_id ){
    return async ( dispatch ) => {
        dispatch( buscarCliente() );
        try {
            const respuesta = await clienteAxios.get(`/clients/${cliente_id}`);
            dispatch( busquedaClienteSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( busquedaClienteError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }    
}

const buscarCliente = () => ({
    type:SEARCH_CLIENT,
    payload:true      
})

const busquedaClienteSuccess = ( cliente ) => ({
    type:SEARCH_CLIENT_SUCCESS,
    payload:cliente         
})

const busquedaClienteError = () => ({
    type:SEARCH_CLIENT_ERROR,
    payload:true        
})

const intentandoBorrarCliente = () => ({
    type:TRYING_DELETE_CLIENTE,
    payload:true   
})
const clienteBorradoConExito = () => ({
    type:CLIENTE_DELETED_SUCCESS,
    payload:true      
})
const clienteBorradoError = () => ({
    type:CLIENTE_DELETED_ERROR,
    payload:true      
})
const haciendoPostNuevoCliente = () => ({
    type:BEGIN_POST_CLIENT,
    payload:true
})

const postNuevoClienteExitoso = (client) => ({
    type:POST_CLIENT_SUCCESS,
    payload:client    
})

const postNuevoClienteError = (error) => ({
    type:POST_CLIENT_ERROR,
    payload:error    
})

const descargarClientes = () => ({
    type:BEGIN_LIST_CLIENTS,
    payload:true
})

const descargaClienteExitosa = (clients) => ({
    type:LIST_CLIENTS_SUCCESS,
    payload:clients
})

const descargaClienteError = () => ({
    type:LIST_CLIENTS_ERROR,
    payload:true   
})


export function upload_masive_excel_action(data){
    return async ( dispatch ) => {
        dispatch( upload_masive_excel_try() );
        try {
            const formData = new FormData();
            formData.append("excel", data[0]);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            const respuesta = await clienteAxios.post(`/clients/upload_masive_excel`,formData,config);
    
            dispatch( upload_masive_excel_success(respuesta.data) )

            if(respuesta.data.success){
                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:'La carga se realizó con éxito!'
                })
                dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error en la carga, intenta de nuevo'
                })
            }

        } catch (error) {
            console.log(error)

            dispatch( upload_masive_excel_error() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }    
}

const upload_masive_excel_try = () => ({
    type:UPLOAD_MASIVE_EXCEL_TRY,
    payload:true      
})

const upload_masive_excel_success = ( data ) => ({
    type:UPLOAD_MASIVE_EXCEL_SUCCESS,
    payload:data         
})

const upload_masive_excel_error = () => ({
    type:UPLOAD_MASIVE_EXCEL_ERROR,
    payload:true        
})
