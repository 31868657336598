import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { LoaderSpinner } from '../../components/LoaderSpinner';
import Swal from 'sweetalert2';


// redux
import { useSelector, useDispatch } from 'react-redux';
import {  obtenerCampaigns, borrarCampanaAction, sendCampaign, duplicate } from '../../actions/campaignsActions';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import ReactTooltip from 'react-tooltip';

import moment from "moment"
import 'moment/locale/es';

export const MailMarketing = () => {

    const dispatch = useDispatch()
    const loading = useSelector( state => state.campaigns.loading )
    const campaigns = useSelector( state => state.campaigns.campaigns )
    const auth = useSelector( state => state.auth.user )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [busco,setBusco] = useState(false);

    const duplicateCampana = async (id) => {
      await dispatch(duplicate(id))
      await dispatch(obtenerCampaigns(`?paginate=true&page=0&size=5`));
    }

    useEffect(() => {
        
        if(!busco){
            dispatch(obtenerCampaigns(`?paginate=true&page=0&size=5`));
            setBusco(true);
        } 
        if(search_custom_page == null){
          $('#loading').hide();
        }
    },[search_custom_page])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
 
    const handleDelete = async ( id ) => {
        if(window.confirm('Estas seguro ?')){

            await dispatch(borrarCampanaAction(id));
            await dispatch(obtenerCampaigns(`?paginate=true&page=${page}&size=5`));
        }
    }

    const handleClickSend = async (id) => {
        console.log('id',id)
        await dispatch(sendCampaign(id));
    }

    const handleChangePage = async (page) => {
      await dispatch(obtenerCampaigns(`?paginate=true&page=${page}&size=5`))

    }
    const handleSearch = async(e) => {
      // console.log(e.target.value)
      await dispatch(obtenerCampaigns(`?paginate=true&page=0&limit=5&size=5&q=${e.target.value}`))
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Email Marketing</span>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="btn-group ml-4">
                <Link to="/layoutlist" className="btn btn-default"><span className="fas fa-sticky-note "></span> Plantillas</Link>
                <Link to="/maillist" className="btn btn-default"><span className="fas fa-list "></span>  Listas</Link>
                <Link to="/create_campaign" className="btn btn-default"><span className="fas fa-plus "></span>  Crear Campaña</Link>
                <Link to="/domains" className="btn btn-default"><span className="fas fa-list "></span>  Dominios</Link>
                {
                   (auth && auth.roles[0].name == 'impulsa') ? <Link to="/mailmarketing_template" className="btn btn-success ml-5"> Plantillas</Link> : null
                }
                
                
            </div>
            <div className="table-responsive">
              <div className="row">
                <div className="col-md-4">
                  <input type="text" className="form-control input_busqueda" onChange={handleSearch} placeholder="Buscar [nombre], [asunto], [remitente], [email remitente]"/>
                </div>
              </div>
            <table className="table">
              <thead>
                <th width="150px">Nombre</th>
                <th width="300px">Asunto</th>
                <th width="300px">Fecha de envío</th>
                <th width="150px">Estado</th>
                <th width="150px"> </th>
                <th width="300px"> </th>
              </thead>
              <tbody>
                {
                campaigns && campaigns.records?.length > 0 ? campaigns.records.map((row) => (
                    <tr key={row.id}>
                      <td>{row.nombre}</td>
                      <td>{row.asunto}</td>
                      <td>{row.first_event.date}</td>
                      <td>{row.estado}</td>
                      <td>{row.seen.count } - { row.count.count != 0 ? (row.seen.count * 100 / row.count.count).toFixed(2) : "0"} % </td>
                      <td>
                        <div className="btn-group mr-2" role="group" aria-label="Basic example">
                            <Link to={`/campaign_edit/${row.id}`} className="btn redondo btn-sm" data-tip="Editar Campaña"><span className="fas fa-edit"></span></Link>
                            <Link to={`/campaign_list/${row.id}`} className="btn redondo btn-sm" data-tip="Editar Listas"><span className="fas fa-list"></span></Link>
                            <Link to={`/campaign_design/${row.id}`} className="btn redondo btn-sm" data-tip="Editar Diseño"><i className="fas fa-drafting-compass"></i></Link>
                            <Link to={`/campaign_design/view/${row.id}`} className="btn redondo btn-sm" data-tip="Ver diseño"><i className="fas fa-eye black_font"></i></Link>
                            
                            {/* <button onClick={ () => handleClickSend(row.id) } className="btn btn-success btn-sm ml-2 mr-2" title="Enviar"><i className="fas fa-send"></i></button> */}
                            <Link to={`/statistics/${row.id}`} className="btn redondo mr-2 numero_feo" data-tip="Ver Estadísticas"><i class="fas fa-sort-numeric-up"></i> </Link>
                            <button className="btn redondo btn-sm" style={{border:"none", background:"none"}} data-tip="Duplicar" onClick={() => duplicateCampana(row.id)}><i class="fas fa-clone"></i></button>
                            <button onClick={ () => handleDelete(row.id) } className="btn redondo_rojo btn-sm  mr-2" data-tip="Borrar Campaña"><span className="fas fa-remove"></span></button>
                            <ReactTooltip />
                        </div>
                      </td>
                    </tr>
                  )) : null
                }
              </tbody>
            </table>
            <div className="text-center">
                  <nav aria-label="Page navigation example">
                      <ul className="pagination">
                          <div className="page-item mr-10">
                              Pág {campaigns.currentPage + 1} -

                              {campaigns.totalItems} Registros 
                          </div>
                          <li className="page-item"><a className="page-link" onClick={ ()=> handleChangePage( campaigns.currentPage>0 ? campaigns.currentPage -1 : 0)}>Anterior</a></li>
                          {
                              [...Array(campaigns.totalPages).keys()].map( (page,index) => {
                                  if(index < 15){

                                      if(page  == campaigns.currentPage ){
                                          return <li className="page-item active">
                                          <a className="page-link" onClick={ ()=> handleChangePage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                          </li>
                                      }else{
                                          return <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(page)} >{page + 1}</a></li>
                                      }
                                  }
                              })
                          }
                          {
                              campaigns.totalPages > 15 ? 
                              <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(campaigns.totalPages-1)}>{campaigns.totalPages-1}</a></li> : null
                          }
                          <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(campaigns.currentPage < campaigns.totalPages -1 ? campaigns.currentPage + 1 : campaigns.totalPages -1)}>Siguiente</a></li>
                      </ul>
                  </nav>
              </div>
          </div>
        </div>
    )
}
