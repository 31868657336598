import React from 'react'
import { Link } from 'react-router-dom';

export const Config = () => {
    return (
        <>
            <div className="p-4">
                <span className="mr-3 naranja">Configuración</span>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ul>
                        <li>
                            <Link to="/custom_fields">Campos Personalizados</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
