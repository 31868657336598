import React,{useState, useEffect} from 'react'
import '../App.css';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/authActions';
import { LoaderSpinner } from '../components/LoaderSpinner';
import { Link } from 'react-router-dom';
import $ from 'jquery'
export const LoginScreen = ({ history }) => {
    const [user, setUser] = useState({
        email:'',
        password:''
    });
    const [remember, setRemember] = useState(false);
    const dispatch = useDispatch()

    const isAuth = useSelector( state => state.auth.isAuthenticated )
    const loading = useSelector( state => state.auth.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    

    useEffect(() => {
        if(isAuth)
        {
            history.push('/home');
        }
        let access = localStorage.getItem('app_marketing_access')
        if(access){
            access = JSON.parse(access);
            if(access.email && access.password){
                setUser({
                    email:access.email,
                    password:access.password
                })
            }
        }
        // console.log('search_custom_page',search_custom_page)
        if(search_custom_page == null){
            $('#loading').hide();
        }
        
    }, [isAuth,search_custom_page])

    const handleSubmit = (e) => {
        e.preventDefault();
        // history.push('/');
        if(remember){
            const token = localStorage.setItem('app_marketing_access',JSON.stringify({email:user.email, password:user.password}));
        }
        dispatch(login(user.email, user.password))
    }

    const handleChange = (e) => {
        if(e.target.name == 'email'){
            setUser({
                ...user,
                [e.target.name] : e.target.value.trim()
            })
        }else{

            setUser({
                ...user,
                [e.target.name] : e.target.value
            })
        }
    }
    return (
      
        <div id="loginWrapper">
            <div id="login">
                    
            
            <div className="container">
                <div id="login-row" className="row justify-content-center align-items-center">
                    <div id="login-column" className="col-md-6">
                        <div id="login-box" className="col-md-12">
                            <div className="text-center mt-4">
                                <img src="/Logos-impulsa-4.png" className="mt-4"/>
                            </div>
                            <form id="login-form" className="form" action="" method="post" onSubmit={ handleSubmit }>
                                <h3 className="text-center colorsito">Inicio de sesión</h3>
                                {
                                    loading ? <LoaderSpinner/> : null
                                }
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Correo Electrónico:</label><br/>
                                    <input type="text" name="email" value={user.email} onChange={ handleChange } id="email" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="label-login">Contraseña:</label><br/>
                                    <input type="password" name="password" value={user.password} onChange={ handleChange } id="password" className="form-control-lg form-control"/>
                                    <label className='mt-1' style={{"fontSize":"13px"}}><input type="checkbox" name="remember" defaultChecked={remember}  onChange={(e) => setRemember(!remember) } style={{"width":"13px"}}/> Recordar datos de sesión</label>
                                </div>
                                <div className="form-group">
                                    {/* <label htmlFor="remember-me" className="label-login"><span>Remember me</span>聽<span><input id="remember-me" name="remember-me" type="checkbox"/></span></label><br/> */}
                                    <input type="submit" name="submit" className="btn btn-info btn-md" value="Iniciar sesi&oacute;n" />

                                    <Link to="/register" className="label-login text-right pull-right colorsito">Registro</Link> 
                                    <div style={{"display":"block","marginTop":"0px"}}>
                                        <Link to="/reset_password" className="label-login text-right pull-right colorsito">Recuperar contrase&ntilde;a</Link> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
