import React, { useEffect, useState } from 'react'

import $ from 'jquery';

import clienteAxios from '../../config/axios';

export const Unsubcribe = () => {
    const [desuscrito, setDesuscrito] = useState(null);
    // console.log(window.location.pathname)
    useEffect(async () => {
        console.log('request Unsubcribe')

        const response = await clienteAxios.post(window.location.pathname);
        console.log('response Unsubcribe', response.data)
        setDesuscrito(response.data.success)
        $('#loading').hide();
    }, [])

    return (
        <div className="white_box QA_section card_height_100">
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 mt-5">
                    {
                        desuscrito ?
                            <div className="text-center alert alert-success">
                                <h2>Desuscrito exitosamente de la lista </h2>
                            </div> :
                            <div className="text-center alert alert-error">
                                <h2>Ha ocurrido un error </h2>
                            </div>
                    }
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    )
}
