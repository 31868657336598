import React from 'react'

export const BreadCrumbLanding = ({active}) => {
  return (
    <div className="container-nav mb-5 bread_crumb_landing">
        <div className="line-crumb"></div>
        <ul className="steps-progress-bar landing">
            <li id="first-step" className={`steps` + (active == 1 ? ' active' : '')}>
                <p className="circle">1</p> <p className="nameCrumb">Crear</p>
            </li>
            <li id="second-step" className={`steps` + (active == 2 ? ' active' : '')}>
                <p className="circle">2</p> <p className="nameCrumb">Editor</p>
            </li>
            <li id="third-step" className={`steps` + (active == 3 ? ' active' : '')}>
            <p className="circle">3</p> <p className="nameCrumb">Config</p>
            </li>
            <li id="fourth-step" className={`steps` + (active == 4 ? ' active' : '')}>
                <p className="circle">4</p>   <p className="nameCrumb">Resumen</p>
            </li>
        </ul>
    </div>
  )
}
