import {


    BEGIN_LIST_CLIENTS,
    LIST_CLIENTS_SUCCESS,
    LIST_CLIENTS_ERROR,

    BEGIN_POST_CLIENT,
    POST_CLIENT_SUCCESS,
    POST_CLIENT_ERROR,

    TRYING_DELETE_CLIENTE,
    CLIENTE_DELETED_SUCCESS,
    CLIENTE_DELETED_ERROR,

    SEARCH_CLIENT_SUCCESS,
    SEARCH_CLIENT_ERROR,
    SEARCH_CLIENT,

    BEGIN_UPDATE_CLIENT,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_ERROR,

    UPLOAD_MASIVE_EXCEL_TRY,
    UPLOAD_MASIVE_EXCEL_SUCCESS,
    UPLOAD_MASIVE_EXCEL_ERROR
}
from '../types';

// cada reducer tiene su propio state

const initialState = {
    clientes: [],
    newclient:null,
    clientedit:null,
    error:null,
    loading:false
}

export default function (state = initialState, action){
    switch(action.type){
        case BEGIN_LIST_CLIENTS:
            return {
                ...state,
                loading:action.payload,
                newclient:null,
                clientedit:null,
                error:null
            }
        case LIST_CLIENTS_SUCCESS:
            return {
                ...state,
                clientes:action.payload,
                loading:false

            }
        case LIST_CLIENTS_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false

            }
        case BEGIN_POST_CLIENT:
            return{
                ...state,
                loading:action.payload,
                newclient:null
            }

        case POST_CLIENT_SUCCESS:
            return{
                ...state,
                newclient:action.payload,
                loading:false
            }
        case POST_CLIENT_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRYING_DELETE_CLIENTE:
            return {
                ...state,
                loading:action.payload
            }
        case CLIENTE_DELETED_SUCCESS:
            return {
                ...state,
                loading:action.payload
            }
        case CLIENTE_DELETED_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false

            }
        case SEARCH_CLIENT:
            return {
                ...state,
                loading:true,
                clientedit:null

            }
        case SEARCH_CLIENT_SUCCESS:
            return {
                ...state,
                clientedit:action.payload,
                loading:false
            }
        case SEARCH_CLIENT_ERROR:
            return {
                ...state,
                clientedit:null,
                loading:false,
                error:true
            }
        case BEGIN_UPDATE_CLIENT:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                loading:false

            }
        case UPDATE_CLIENT_ERROR:
            return {
                ...state,
                loading:false

            }
        case UPLOAD_MASIVE_EXCEL_TRY:
            return {
                ...state,
                loading:true
            }
        case UPLOAD_MASIVE_EXCEL_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case UPLOAD_MASIVE_EXCEL_ERROR:
            return {
                ...state,
                loading:false
            }
        default:
            return state;
    }
}