import React, { useEffect } from 'react'
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    useLocation
  } from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
// import { LoginScreen } from '../screens/LoginScreen';
// import { AboutScreen } from '../screens/AboutScreen';
import { Navbar } from '../screens/Navbar';
import { TopNav } from '../components/TopNav';
import { ClientList } from '../screens/clients/ClientList';
import { ContactList } from '../screens/contacts/ContactList';
import { NewClient } from '../screens/clients/NewClient';
import { NewContact } from '../screens/contacts/NewContact';
import { EditClient } from '../screens/clients/EditClient';
import { ViewClient } from '../screens/clients/ViewClient';
import { EditContact } from '../screens/contacts/EditContact';
import { ViewContact } from '../screens/contacts/ViewContact';
import { Perfil } from '../screens/perfil/Perfil';
import { Config } from '../screens/config/Config';
import { MailMarketing } from '../screens/mailMarketing/MailMarketing';
import { CreateMailList } from '../screens/mailList/CreateMailList';
import { MailList } from '../screens/mailList/MailList';
import { ViewMailList } from '../screens/mailList/ViewMailList';
import { EditMailList } from '../screens/mailList/EditMailList';
import { LayoutList } from '../screens/layouts/LayoutList';
import { LayoutCreate } from '../screens/layouts/LayoutCreate';
import { LayoutEdit } from '../screens/layouts/LayoutEdit';
import { LayoutView } from '../screens/layouts/LayoutView';
import { LayoutConfig } from '../screens/layouts/LayoutConfig.js';
import { Basic } from '../screens/layouts/Basic';
import { Theme } from '../screens/layouts/Theme';
import { Editor } from '../screens/layouts/Editor';
import { CustomFieldsIndex } from '../screens/custom_fields/CustomFieldsIndex';
import { CreateCampaign } from '../screens/campaigns/CreateCampaign';
import { CampaignList } from '../screens/campaigns/CampaignList';
import { EditCampaign } from '../screens/campaigns/EditCampaign';
import { CampaignDesign } from '../screens/campaigns/CampaignDesign';
import { CampaignPlantext } from '../screens/campaigns/CampaignPlantext';
import { BasicDesign } from '../screens/campaigns/basicDesign';
import { TextEditor } from '../screens/campaigns/TextEditor';
import { CampaignHtml } from '../screens/campaigns/CampaignHtml';
import { CampaignEditor } from '../screens/layouts/CampaignEditor';
import { View } from '../screens/campaigns/View';
import { AdminAccount } from '../screens/admin/AdminAccount';
import { NewAccount } from '../screens/admin/NewAccount';
import { EditAccount } from '../screens/admin/EditAccount';

import { useSelector, useDispatch } from 'react-redux';
import { verificarSesion, logout } from '../actions/authActions';
import { LandingPages } from '../screens/lading_pages/LandingPages';
import { CreateLandingPage } from '../screens/lading_pages/CreateLandingPage';
import { EditLandingPage } from '../screens/lading_pages/EditLandingPage';
import { DrawLanding } from '../screens/lading_pages/DrawLanding';
import { PreviewLanding } from '../screens/lading_pages/PreviewLanding';
import { NewDesign } from '../screens/lading_pages/NewDesign';
import { DesignList } from '../screens/lading_pages/DesignList';
import { FormLanding } from '../screens/lading_pages/FormLanding';
import { Statistics } from '../screens/mailMarketing/Statistics';
import { Domains } from '../screens/domains/Domains'


import { UsersList } from '../screens/perfil/UsersList';
import { MailMarketingTemplate } from '../screens/mailMarketing/MailMarketingTemplate';
import { NewDesignMailTemplate } from '../screens/mailMarketing/NewDesignMailTemplate';
import { WithImpulsa } from '../screens/admin/WithImpulsa';


export const DashboardRoutes = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const userAuth = useSelector( state => state.auth.user )
    const current_landing = useSelector( state => state.landings.current_landing )
    const isHome = () => {
        return window.location.href.search('home') !== -1 ? true : false
    }

    useEffect(async () => {

        // const landing = await checkCustomerLandingPage();
        // if(landing.data.success){
        //     dispatch(set_current_landing_page(landing.data.landing))
        // }

        await dispatch(verificarSesion());
        
        
        if(userAuth){
            if(userAuth.isActive != true){
                await dispatch(logout());
            }
        }
        
    }, [location]);

    

    return (
        <>
            {
                current_landing ? 
                    <div>
                        <div dangerouslySetInnerHTML={{__html: JSON.parse(current_landing.html).html }}>
                        </div>
                    </div>
                : 
                <>
                <Navbar/>
                <section className="main_content dashboard_part">
                <TopNav/>
                  <Switch>
                    <div className="main_content_iner " style={{paddingTop: isHome() ? "120px" : "0px", marginTop: isHome() ? "auto" : "-60px"}}>
                        <div className="container-fluid p-0 sm_padding_15px">
                            <div className="">
                                        <Route exact path="/home" component={ HomeScreen } />
                                        <Route exact path="/users_list" component={ UsersList } />
                                        <Route exact path="/clients" component={ ClientList } />
                                        <Route exact path="/new_client" component={ NewClient } />
                                        <Route exact path="/edit_client/:id" component={ EditClient } />
                                        <Route exact path="/view_client/:id" component={ ViewClient } />
                                        <Route exact path="/contacts" component={ ContactList } />
                                        <Route exact path="/new_contact" component={ NewContact } />
                                        <Route exact path="/edit_contact/:id" component={ EditContact } />
                                        <Route exact path="/view_contact/:id" component={ ViewContact } />

                                        <Route exact path="/profile" component={ Perfil } />
                                        <Route exact path="/config" component={ Config } />
                                        <Route exact path="/mail_marketing" component={ MailMarketing } />
                                        <Route exact path="/statistics/:idCampaign" component={ Statistics } />

                                        <Route exact path="/maillist" component={ MailList } />
                                        <Route exact path="/maillist_create" component={ CreateMailList } />
                                        <Route exact path="/view_maillist/:id" component={ ViewMailList } />
                                        <Route exact path="/edit_maillist/:id" component={ EditMailList } />

                                        <Route exact path="/layoutlist" component={ LayoutList } />
                                        <Route exact path="/layout_create" component={LayoutCreate}/>
                                        <Route exact path="/layout_edit/:id" component={LayoutEdit}/>
                                        <Route exact path="/layout_view/:id" component={LayoutView}/>
                                        <Route exact path="/layout_config/:id" component={LayoutConfig}/>
                                        <Route exact path="/layout_basic/:id" component={Basic}/>
                                        <Route exact path="/layout_template/:id" component={Theme}/>
                                        <Route exact path="/layout_editor/:id" component={Editor}/>


                                        <Route exact path="/custom_fields" component={ CustomFieldsIndex }/>


                                        <Route exact path="/create_campaign" component={ CreateCampaign } />
                                        <Route exact path="/campaign_list/:id" component={ CampaignList } />
                                        <Route exact path="/campaign_edit/:id" component={ EditCampaign } />

                                        <Route exact path="/campaign_design/:id" component={ CampaignDesign } />
                                        <Route exact path="/campaign_design/html/:idCampaign" component={ CampaignHtml } />
                                        <Route exact path="/campaign_design/plaintext/:idCampaign" component={ CampaignPlantext } />

                                        <Route exact path="/campaign_design/plaintext/basicDesign/:idCampaign" component={ BasicDesign } />
                                        <Route exact path="/campaign_design/plaintext/basicDesign/textEditor/:idCampaign" component={ TextEditor } />
                                        <Route exact path="/campaign_editor/:idCampaign/:campaign_editor_type" component={ CampaignEditor } />

                                        <Route exact path="/campaign_design/view/:idCampaign" component={ View } />


                                        <Route exact path="/landing_pages" component={ LandingPages } />
                                        <Route exact path="/new_landing_page" component={ CreateLandingPage } />
                                        <Route exact path="/edit_landing/:id" component={ EditLandingPage } />
                                        <Route exact path="/draw_landing/:id" component={ DrawLanding } />
                                        <Route exact path="/preview_landing/:id" component={ PreviewLanding } />
                                        <Route exact path="/form_landing/:id" component={ FormLanding } />
                                        

                                        {/* admin */}
                                        <Route exact path="/admin_accounts" component={ AdminAccount } />
                                        <Route exact path="/with_impulsa" component={ WithImpulsa } />
                                        
                                        <Route exact path="/edit_user/:id" component={ EditAccount } />
                                        <Route exact path="/new_design" component={ NewDesign } />
                                        <Route exact path="/design_list" component={ DesignList } />
                                        
                                        <Route exact path="/new_account" component={ NewAccount } />
                                        <Route exact path="/mailmarketing_template" component={ MailMarketingTemplate } />
                                        <Route exact path="/new_design_mail_template" component={ NewDesignMailTemplate } />
                                        <Route exact path="/domains" component={ Domains } />

                                        {/* <Route component={ NotFoundScreen }></Route> */}
                                </div>
                            </div>
                        </div>
                                    </Switch>
                </section>
                </>
            }
        </>
    )
}
