import {
    // ADD_CONTACT,
    // ADD_CONTACT_SUCCESS,
    // ADD_CONTACT_ERROR,

    BEGIN_LIST_CONTACTS,
    LIST_CONTACTS_SUCCESS,
    LIST_CONTACTS_ERROR,

    BEGIN_NEW_CONTACTO,
    NEW_CONTACTO_SUCCESS,
    NEW_CONTACTO_ERROR,

    SEARCH_CONTACT,
    SEARCH_CONTACT_SUCCESS,
    SEARCH_CONTACT_ERROR,

    BEGIN_UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,

    TRYING_DELETE_CONTACTO,
    CONTACTO_DELETED_SUCCESS,
    CONTACTO_DELETED_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerContactosAction({paginate,stringparams}){
    return async ( dispatch ) => {
        dispatch( descargarContactos() );
        try {
            let respuesta;
            if(paginate ==  true){
                respuesta = await clienteAxios.get(`/contacts?paginate=true&${stringparams}`);
            }else{
                respuesta = await clienteAxios.get('/contacts');
            }

            dispatch( descargaContactoExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaContactoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarContactos = () => ({
    type:BEGIN_LIST_CONTACTS,
    payload:true
})

const descargaContactoExitosa = (contacts) => ({
    type:LIST_CONTACTS_SUCCESS,
    payload:contacts
})

const descargaContactoError = () => ({
    type:LIST_CONTACTS_ERROR,
    payload:true   
})

export function crearNuevoContacto( contacto ){
    return async ( dispatch ) => {
        dispatch( haciendoPostContacto() );
        try {
            const respuesta = await clienteAxios.post('/contacts', contacto);

            dispatch( haciendoPostSuccess(respuesta.data) )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Contacto creado con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( haciendoPostError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }    
}

const haciendoPostContacto = () => ({
    type:BEGIN_NEW_CONTACTO,
    payload:true
})

const haciendoPostSuccess = ( contacto ) => ({
    type:NEW_CONTACTO_SUCCESS,
    payload:contacto    
})

const haciendoPostError = ( error ) => ({
    type:NEW_CONTACTO_ERROR,
    payload:error    
})

export function obtenerContacto( contacto_id ){
    return async ( dispatch ) => {
        dispatch( buscarContacto() );
        try {
            const respuesta = await clienteAxios.get(`/contacts/${contacto_id}`);

            dispatch( busquedaContactoSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( busquedaContactoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }    
}

const buscarContacto = () => ({
    type:SEARCH_CONTACT,
    payload:true      
})

const busquedaContactoSuccess = ( cliente ) => ({
    type:SEARCH_CONTACT_SUCCESS,
    payload:cliente         
})

const busquedaContactoError = () => ({
    type:SEARCH_CONTACT_ERROR,
    payload:true        
})

export function actualizarContacto( contacto ){
    return async ( dispatch ) => {
        console.log('contacto',contacto )
        dispatch( comienzoActualizarContacto() );
        try {
            const respuesta = await clienteAxios.put(`/contacts/${contacto.id}`,contacto);
            // console.log('respuesta',respuesta)
            dispatch( actualizarContactoExitoso(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Actualización realizada con éxito!.'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarContactoError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }       
}
const comienzoActualizarContacto = () => ({
    type:BEGIN_UPDATE_CONTACT,
    payload:true
})
const actualizarContactoExitoso = ( contacto ) => ({
    type:UPDATE_CONTACT_SUCCESS,
    payload:contacto
})
const actualizarContactoError = ( error ) => ({
    type:UPDATE_CONTACT_ERROR,
    payload:error
})

export function borrarContacto( cliente_id ){
    return async ( dispatch ) => {
        dispatch( intentandoBorrarContacto() );
        try {
             await clienteAxios.delete(`/contacts/${cliente_id}`);
            // console.log('respuesta post client',respuesta)
            dispatch( contactoBorradoConExito() )

        } catch (error) {
            console.log(error)

            dispatch( contactoBorradoError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}   

const intentandoBorrarContacto = () => ({
    type:TRYING_DELETE_CONTACTO,
    payload:true   
})
const contactoBorradoConExito = () => ({
    type:CONTACTO_DELETED_SUCCESS,
    payload:true      
})
const contactoBorradoError = () => ({
    type:CONTACTO_DELETED_ERROR,
    payload:true      
})