import React from 'react'

export const NotFoundScreen = () => {
    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1>
                                Oops!</h1>
                            <h2>
                                404 No Encontrada</h2>
                            <div className="error-details">
                                Disculpe, ocurrió un error, la página no ha sido encontrada!
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
