import {


    BEGIN_LIST_CONTACTS,
    LIST_CONTACTS_SUCCESS,
    LIST_CONTACTS_ERROR,

    BEGIN_NEW_CONTACTO,
    NEW_CONTACTO_SUCCESS,
    NEW_CONTACTO_ERROR,

    SEARCH_CONTACT,
    SEARCH_CONTACT_SUCCESS,
    SEARCH_CONTACT_ERROR,

    BEGIN_UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,

    TRYING_DELETE_CONTACTO,
    CONTACTO_DELETED_SUCCESS,
    CONTACTO_DELETED_ERROR
}
from '../types';

// cada reducer tiene su propio state

const initialState = {
    contactos: [],
    newcontacto:null,
    contactdit:null,
    error:null,
    loading:false
}

export default function (state = initialState, action){
    switch(action.type){
        case BEGIN_LIST_CONTACTS:
            return {
                ...state,
                loading:action.payload,
                contactdit:null,
                error:null
            }
        case LIST_CONTACTS_SUCCESS:
            return {
                ...state,
                contactos:action.payload,
                loading:false,
                error:null

            }
        case LIST_CONTACTS_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false

            }
        case BEGIN_NEW_CONTACTO:
            return {
                ...state,
                loading:true
            }
        case NEW_CONTACTO_SUCCESS:
            return {
                ...state,
                newcontacto:action.payload,
                loading:false
            }
        case NEW_CONTACTO_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }     
        case SEARCH_CONTACT:
            return {
                ...state,
                loading:true

            }
        case SEARCH_CONTACT_SUCCESS:
            return {
                ...state,
                contactdit:action.payload,
                loading:false
            }
        case SEARCH_CONTACT_ERROR:
            return {
                ...state
            }
        case BEGIN_UPDATE_CONTACT:
            return {
                ...state,
                loading:true,
            }
        case UPDATE_CONTACT_SUCCESS:
            return {
                ...state,
                loading:false,

            }
        case UPDATE_CONTACT_ERROR:
            return {
                ...state,
                loading:false,

            }
        case TRYING_DELETE_CONTACTO:
            return {
                ...state,
                loading:action.payload
            }
        case CONTACTO_DELETED_SUCCESS:
            return {
                ...state,
                loading:action.payload
            }
        case CONTACTO_DELETED_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false

            }
        default:
            return state;
    }
}