import {

    TRY_GET_DOMAINS,
    GET_DOMAINS_SUCCESS,
    GET_DOMAINS_ERROR,

    TRY_SAVE_DOMAIN,
    SAVE_DOMAIN_SUCCESS,
    SAVE_DOMAIN_ERROR,

    TRY_VERIFY_DOMAIN,
    VERIFY_DOMAIN_SUCCESS,
    VERIFY_DOMAIN_ERROR
}
from '../types';
// cada reducer tiene su propio state

const initialState = {
    domains:[],
    error:null,
    loading:false
}

export default function (state = initialState, action){
    switch(action.type){
        case TRY_SAVE_DOMAIN:
            return {
                ...state,
                loading:true
            }
        case SAVE_DOMAIN_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case SAVE_DOMAIN_ERROR:
            return {
                ...state,
                loading:false
            }
        case TRY_GET_DOMAINS:
            return {
                ...state,
                loading:true,
                domains:[]
            }
        case GET_DOMAINS_SUCCESS:
            return {
                ...state,
                loading:false,
                domains:action.payload
            }
        case GET_DOMAINS_ERROR:
            return {
                ...state,
                loading:false,
                domains:[]
            }

        case TRY_VERIFY_DOMAIN:
            return{
                ...state,
                loading:true
            }
        case VERIFY_DOMAIN_SUCCESS:
            return{
                ...state,
                loading:false
            }
        case VERIFY_DOMAIN_ERROR:
            return{
                ...state,
                loading:false
            }
        default:
            return state;
    }
}