import React,{ useEffect, useState} from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {  getLandingById, getFormInfo, duplicate } from '../../actions/landingActions';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { BreadCrumbLanding } from '../../components/BreadCrumbLanding';

export const PreviewLanding = () => {
    const dispatch = useDispatch()

    const [busco, setBusco] = useState(false);
    const { id } = useParams()
    const landing = useSelector( state => state.landings.landingEdit )
    const forminfo = useSelector( state => state.landings.forminfo )
    const user = useSelector( state => state.auth.user )
    const [html, setHtml] = useState('');

    useEffect(async ()=>{
        if(!busco){
            await dispatch(getLandingById(id))
            console.log({id})
            await dispatch(getFormInfo({id}));
            
            setBusco(true);
            if(landing){

                const htmlget = JSON.parse(landing.html);
                console.log(htmlget)
                setHtml(htmlget?.html);
            }
        }
    },[landing])

    const duplicateLanding = async (landing_id ) => {
        await dispatch(duplicate(landing_id))
        Swal.fire({
            icon:'success',
            title:'�0�7xito',
            text:'Landing page ha sido duplicada'
        })
    }

    return (
        <div className="white_box QA_section card_height_100">
                <BreadCrumbLanding active={4}/>

            <Link to="/landing_pages" className="btn btn-link mb-2 mt-2">Volver</Link>
            <div className="row">
                <div className="col-md-4">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label>Url:</label>
                            <p>
                                {landing && user && 
                                    <a target="_blank" href={window.location.origin+"/p/"+user.account_name + "/" + landing.url }>{window.location.origin+"/p/"+user.account_name + "/" + landing.url }</a>
                                }
                            </p>
                        </li>
                        <li className="list-group-item">
                            <p>
                            <label className="mr-2">Visitas:</label>
                                {landing && 
                                   landing.visitas
                                }
                            </p>
                            <p>
                            <label className="mr-2">Conversiones:</label>
                                {landing && 
                                   landing.conversiones
                                }
                            </p>
                            <p>
                            <label className="mr-2">% Conversiones:</label>
                                {landing && 
                                   (landing.conversiones && landing.visitas) ? ((landing.conversiones * 100 / landing.visitas).toFixed(2) +'%') : '0%'
                                }
                            </p>
                        
                        </li>
                        <li className="list-group-item">
                            <label>Propietario:</label>
                            <p className="ml-2"> <b>Nombre: </b>
                                {landing && 
                                    landing.propietario.nombre
                                }
                            </p>
                            <p className="ml-2"> <b>Nombre de cuenta:  </b>
                                {landing && 
                                    landing.propietario.nombre_cuenta
                                }
                            </p>
                            <p className="ml-2"> <b>Email:  </b>
                                {landing && 
                                    landing.propietario.email
                                }
                            </p>
                        </li>
                        <li className="list-group-item">
                            <label>Fecha de creaci&oacute;n:</label>
                            <p>
                                {landing && 
                                    moment(landing.createdAt).fromNow()
                                }
                            </p>
                        </li>
                        <li className="list-group-item">
                            { landing && <Link className="mr-4" title="Actualizar Datos" to={`/edit_landing/${landing.id}`}>Editar <i className="far fa-edit"></i></Link>}
                            { landing && <button className="mr-4 btn-link" style={{border:"none", background:"none"}} title="Duplicar" onClick={() => duplicateLanding(landing.id)}>Duplicar <i class="fas fa-clone"></i></button>}
                        </li>
                    </ul>
                </div>
                <div className="col-md-8">
                    <div dangerouslySetInnerHTML={{__html: html}} className="mb-5"></div>
                </div>
                <div>
                    {landing && user && <img
                        src={`http://api.screenshotlayer.com/api/capture?access_key=1a7e0b63352fcf2cabb5c7b90d0a2c09&url=${window.location.origin}/p/${user.account_name}/${landing.url}}>${window.location.origin}/p/${user.account_name}/${landing.url}`}
                    />}
                </div>
            </div>
            
        </div>
    )
}
