import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    OBTENER_USUARIO,
    SET_CURRENT_MENU,
    SET_HOME_DATA
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function setCurrentMenu(menu){
    return async (dispatch) => {
        dispatch(actionSetCurrentMenu(menu));
    }
}
const actionSetCurrentMenu = (menu) => ({
    type:SET_CURRENT_MENU,
    payload:menu
})


export function set_session_with_params(params) {

    return async (dispatch) => {
        try {
            dispatch(obtenerSesion(params));
           
        } catch (error) {

            console.log(error);
            dispatch(logout());
        }
        
    }
}
export function verificarSesion(){
    return async (dispatch) => {
        try {
            const result = await clienteAxios.post('/users/me');
            // console.log('result verificarSesion',result.data)
            dispatch(obtenerSesion(result.data));
           
        } catch (error) {

            console.log(error);
            dispatch(logout());
        }
        
    }
}
const obtenerSesion = (usuario) => ({
    type:OBTENER_USUARIO,
    payload:usuario
})


export function login( email, password ){
    return async ( dispatch ) => {
        dispatch( tryLogin() );
        try {
            const respuesta = await clienteAxios.post(`/auth/signin`, {email,password});
            // console.log('respuesta',respuesta.data)
            dispatch( loginSuccess(respuesta.data) )
            clienteAxios.defaults.headers['x-access'] = respuesta.data.token
        } catch (error) {
            console.log(error.response.data)

            dispatch( loginError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error el error',
                text:error.response.data.message
            })
        }
    }   
}  

const tryLogin = () => ({
    type:LOGIN,
    payload:true
})


const loginSuccess = ( userData ) => ({
    type:LOGIN_SUCCESS,
    payload:userData
})

const loginError = () => ({
    type:LOGIN_ERROR,
    payload:true
})

export function logout(history){
    return async ( dispatch ) => {
        dispatch( tryLogout() );
        try {
            // const respuesta = await clienteAxios.post(`/auth/signin`, {email,password});
            // console.log('respuesta',respuesta.data)
            dispatch( logoutSuccess() )
            delete clienteAxios.defaults.headers['x-access']
            history.push("/login");
        } catch (error) {
            console.log(error)

            dispatch( logoutError(error.message) );

            //alerta de error
            // Swal.fire({
            //     icon:'error',
            //     title:'Hubo un error 1',
            //     text:'Hubo un error, intenta de nuevo'
            // })
        }
    }   
}  

const tryLogout = () => ({
    type:LOGOUT,
    payload:true
})


const logoutSuccess = (  ) => ({
    type:LOGOUT_SUCCESS,
    payload:true
})

const logoutError = () => ({
    type:LOGOUT_ERROR,
    payload:true
})


export function setHomeDataAction(data){
    return async ( dispatch ) => {
        dispatch(setHomeData(data));
    }   
}  

const setHomeData = (data) => ({
    type:SET_HOME_DATA,
    payload:data
})

