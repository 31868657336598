import React, { useState, useEffect } from 'react';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerCliente, actualizarCliente } from '../../actions/clientesActions';
import { obtenerClientsFieldsAction } from '../../actions/customFieldsActions';

export const EditClient = () => {
    let history = useHistory();

    const [clientId, setClientId] = useState(null);
    const dispatch = useDispatch();

    const loading = useSelector(state => state.clientes.loading);
    const error = useSelector(state => state.clientes.error);
    const clienteSearch = useSelector(state => state.clientes.clientedit);
    const search_custom_page = useSelector(state => state.landings.search_custom_page);

    const clientsfields = useSelector(state => state.customFields.clientsfields.normal);
    const clientsfieldsCustom = useSelector(state => state.customFields.clientsfields.custom);

    useEffect(async () => {
        await dispatch(obtenerClientsFieldsAction());
        const idTrozos = window.location.pathname.split('/');
        if (idTrozos.length == 3) {
            setClientId(idTrozos[2]);
        }
        if (clientId && clienteSearch === null) {
            dispatch(obtenerCliente(clientId));
        }
        if (clienteSearch && clienteSearch.nombre) {
            setCliente(clienteSearch);
        }
        if (search_custom_page == null) {
            $('#loading').hide();
        }
    }, [clientId, clienteSearch, search_custom_page]);

    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        creador: '',
        razon_social: '',
        mail: [],
        telefono: [],
        web: '',
        comentario: '',
        estado: '',
        visibilidad: '',
        tags: [],
        origen: [],
        id: clientId
    });

    const handleChange = (e) => {
        setCliente({
            ...cliente,
            [e.target.name]: e.target.value
        });
    };

    // Función para encontrar el campo por nombre
    const getField = (fieldName) => {
        return clientsfields.find(field => field.name.toLowerCase() === fieldName.toLowerCase() || field.custom_name?.toLowerCase() === fieldName.toLowerCase());
    };

    // Cambios en handleSubmit: Validación basada en nombres en lugar de posiciones
    const handleSubmit = async (e) => {
        e.preventDefault();

        const requiredFields = clientsfields.filter(field => field.required);
        const fieldErrors = requiredFields.map(field => {
            const fieldName = field.custom_name || field.name;
            let fieldKey = fieldName.toLowerCase().replace(' ', '_');
            let fieldValue = cliente[fieldKey];
            if (fieldKey === 'email') {
                fieldValue = cliente['mail'];
            } else if (fieldKey === 'telefono') {
                fieldValue = cliente['telefono'];
            } else if (fieldKey === 'tags') {
                fieldValue = cliente['tags'];
            } else if (fieldKey === 'origen') {
                fieldValue = cliente['origen'];
            }

            console.error(fieldKey, fieldValue, cliente);

            // Verificar si el campo es un array y está vacío
            if (Array.isArray(fieldValue)) {
                if (fieldValue.length === 0) {
                    return fieldName;
                }
            } else {
                if (!fieldValue) {
                    return fieldName;
                }
            }

            return null;
        }).filter(error => error !== null);

        if (fieldErrors.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: `Los siguientes campos son requeridos: ${fieldErrors.join(', ')}`
            });
            return;
        }

        // Enviar
        await dispatch(actualizarCliente(cliente));
        history.push('/clients');
    };

    const handleChangeCustom = (e) => {
        const daticos = e.target.name.replace(/\[|\]/gi, '').split('custom')[1];
        if (e.target.type == 'text') {
            setCliente({
                ...cliente,
                custom: {
                    ...cliente.custom,
                    [daticos]: e.target.value
                }
            });
        } else {
            const updatedOptions = [...e.target.options]
                .filter(option => option.selected)
                .map(x => x.value);
            setCliente({
                ...cliente,
                custom: {
                    ...cliente.custom,
                    [daticos]: updatedOptions
                }
            });
        }
    };

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Editar Cliente</span>
            </div>
            {
                loading ? <LoaderSpinner /> : null
            }
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Nombre')?.custom_name) ? getField('Nombre').custom_name : "Nombre"}</label>
                        <input type="text" value={cliente.nombre} onChange={handleChange} className="form-control-lg form-control" name="nombre" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Apellido')?.custom_name) ? getField('Apellido').custom_name : "Apellido"}</label>
                        <input type="text" value={cliente.apellido} onChange={handleChange} className="form-control-lg form-control" name="apellido" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Razón Social')?.custom_name) ? getField('Razón Social').custom_name : "Razón Social"}</label>
                        <input type="text" value={cliente.razon_social} onChange={handleChange} className="form-control-lg form-control" name="razon_social" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Email')?.custom_name) ? getField('Email').custom_name : "Email"}</label>
                        <ReactTagInput
                            tags={cliente.mail}
                            placeholder="Ingrese el email, y presione enter."
                            onChange={(newMails) => setCliente({ ...cliente, mail: newMails })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Teléfono')?.custom_name) ? getField('Teléfono').custom_name : "Teléfono"}</label>
                        <ReactTagInput
                            tags={cliente.telefono}
                            placeholder="Ingrese el telefono, y presione enter."
                            onChange={(newtlfs) => setCliente({ ...cliente, telefono: newtlfs })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Web')?.custom_name) ? getField('Web').custom_name : "Web"}</label>
                        <input type="text" value={cliente.web} onChange={handleChange} className="form-control-lg form-control" name="web" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Comentario')?.custom_name) ? getField('Comentario').custom_name : "Comentario"}</label>
                        <textarea value={cliente.comentario} onChange={handleChange} className="form-control-lg form-control" name="comentario"></textarea>
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Tags')?.custom_name) ? getField('Tags').custom_name : "Tags"}</label>
                        <ReactTagInput
                            tags={cliente.tags}
                            placeholder="Ingrese el texto de los tags, y presione enter."
                            onChange={(newTags) => setCliente({ ...cliente, tags: newTags })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Origen')?.custom_name) ? getField('Origen').custom_name : "Origen"}</label>
                        <ReactTagInput
                            tags={cliente.origen}
                            placeholder="Ingrese el texto de los origenes, y presione enter."
                            onChange={(newOrigen) => setCliente({ ...cliente, origen: newOrigen })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Estado')?.custom_name) ? getField('Estado').custom_name : "Estado"}</label>
                        <select value={cliente.estado} className="form-control-lg form-control" onChange={handleChange} name="estado">
                            <option value="activo">Activo</option>
                            <option value="bloqueado">Bloqueado</option>
                            <option value="papelera">Papelera</option>
                        </select>
                    </div>
                    <div className="form-group" style={{ "display": "none" }}>
                        <label>{(clientsfields && clientsfields.length && getField('Visibilidad')?.custom_name) ? getField('Visibilidad').custom_name : "Visibilidad"}</label>
                        <select value={cliente.visibilidad} className="form-control-lg form-control" onChange={handleChange} name="visibilidad">
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    {
                        clientsfieldsCustom && clientsfieldsCustom.length ? clientsfieldsCustom.map(input => (
                            <div className="form-group">
                                <label>{input.name}</label>
                                {
                                    input.type !== 'text' ?
                                        input.type === 'select' ?
                                            <select required={input.required ? true : false} options={input.options} value={cliente.custom ? cliente.custom[input.name] : null} className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom][" + input.name + "]"}>
                                                <option>Seleccione</option>
                                                {
                                                    input.options?.map((option, i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                            :
                                            <select required={input.required ? true : false} options={input.options} value={cliente.custom ? cliente.custom[input.name] : null} multiple className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom][" + input.name + "]"}>
                                                {
                                                    input.options?.map((option, i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                        : <input type="text" onChange={handleChangeCustom} value={cliente.custom ? cliente.custom[input.name] : null} className="form-control-lg form-control" name={"[custom][" + input.name + "]"} />
                                }
                            </div>
                        )) : null
                    }
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <input type="submit" className="btn btn-success" value="Actualizar" />
                        <Link to="/clients" className="p-2 colorsito">Volver</Link>
                    </div>
                </div>
            </form>
        </div>
    );
};
