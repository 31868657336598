import React, {useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link } from 'react-router-dom'
import $ from 'jquery'

// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerCliente, actualizarCliente } from '../../actions/clientesActions';
import { obtenerClientsFieldsAction } from '../../actions/customFieldsActions';

export const ViewClient = () => {
    
    const [clientId, setClientId] = useState(null);
    const dispatch = useDispatch()

    const loading = useSelector( state => state.clientes.loading )
    const error = useSelector( state => state.clientes.error )
    const clienteSearch = useSelector( state => state.clientes.clientedit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const clientsfieldsCustom = useSelector( state => state.customFields.clientsfields.custom )
    
    const clientsfields = useSelector( state => state.customFields.clientsfields.normal )

    useEffect( async () => {
        await dispatch(obtenerClientsFieldsAction());
        // console.log('window.location.pathname',)
        const idTrozos = window.location.pathname.split('/');
        if(idTrozos.length == 3){
            setClientId(idTrozos[2]);
        }
        if(clientId && clienteSearch === null){
            console.log('clientId',clientId)
            dispatch(obtenerCliente(clientId))
        }
        if(clienteSearch){
            setCliente(clienteSearch);
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[clientId,clienteSearch,search_custom_page])


    
    const [cliente,setCliente] = useState({
        nombre:'',
        apellido:'',
        creador:'',
        razon_social:'',
        mail:[],
        telefono:[],
        web:'',
        comentario:'',
        estado:'',
        visibilidad:'',
        tags:[],
        origen:[]
    });

 


    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Ver Cliente</span>
            </div>

        {
            loading ? <LoaderSpinner/> : null
        }
            <div className="row" >
            <div className="col-md-6">

                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[0].custom_name) ? clientsfields[0].custom_name : "Nombre"}</label>
                    <input type="text" disabled value={cliente.nombre} className="form-control-lg form-control" name="nombre"/>
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[1].custom_name) ? clientsfields[1].custom_name : "Apellido"}</label>
                    <input type="text" disabled value={cliente.apellido} className="form-control-lg form-control" name="apellido"/>
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[2].custom_name) ? clientsfields[2].custom_name : "Razón Social" }</label>
                    <input type="text" disabled value={cliente.razon_social} className="form-control-lg form-control" name="razon_social"/>
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[3].custom_name) ? clientsfields[3].custom_name : "Email"}</label>
                    <ReactTagInput 
                        
                        tags={cliente.mail} 
                        placeholder="Ingrese el email, y presione enter."
                        onChange={(newMails) => {
                            console.log(newMails);
                            setCliente({ ...cliente, mail:newMails});
                            
                        }
                        }
                    />
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[4].custom_name) ? clientsfields[4].custom_name : "Teléfono" }</label>
                    <ReactTagInput 
                        
                        tags={cliente.telefono} 
                        placeholder="Ingrese el telefono, y presione enter."
                        onChange={(newtlfs) => setCliente({ ...cliente, telefono:newtlfs})}
                    />
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[5].custom_name) ? clientsfields[5].custom_name : "Web" }</label>
                    <input type="text" value={cliente.web} disabled className="form-control-lg form-control" name="web"/>
                </div>
                <div className="form-group">
                    <label>Suscripción</label>
                    <input type="text" value={cliente.unsubcribe} disabled className="form-control-lg form-control" name="unsubcribe"/>
                </div>
            </div>
            <div className="col-md-6">
                
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[6].custom_name) ? clientsfields[6].custom_name : "Comentario"}</label>
                    <textarea value={cliente.comentario} disabled className="form-control-lg form-control" name="comentario"></textarea>
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[7]?.custom_name) ? clientsfields[7]?.custom_name :"Tags" }</label>
                    <ReactTagInput 
                        
                        tags={cliente.tags} 
                        placeholder="Ingrese el texto de los tags, y presione enter."
                        onChange={(newTags) => setCliente({ ...cliente, tags:newTags})}
                    />
                   
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[8]?.custom_name) ? clientsfields[8]?.custom_name :"Origen" }</label>
                    <ReactTagInput 
                        
                        tags={cliente.origen} 
                        placeholder="Ingrese el texto de los origenes, y presione enter."
                        onChange={(newOrigen) => setCliente({ ...cliente, origen:newOrigen})}
                    />
                  
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[9]?.custom_name) ? clientsfields[9]?.custom_name :"Estado" }</label>
                    <select value={cliente.estado} disabled  className="form-control-lg form-control" name="estado">
                        <option value="activo">Activo</option>
                        <option value="bloqueado">Bloqueado</option>
                        <option value="papelera">Papelera</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>{ (clientsfields && clientsfields.length && clientsfields[10]?.custom_name) ? clientsfields[10]?.custom_name :"Visibilidad" }</label>
                    <select value={cliente.visibilidad} disabled  className="form-control-lg form-control" name="visibilidad">
                        <option value="admin">Admin</option>
                    </select>
                </div>
                {
                        clientsfieldsCustom && clientsfieldsCustom.length ? clientsfieldsCustom.map( input => (
                            <div className="form-group">
                                <label>{ input.name }</label>
                                {
                                    input.type !== 'text' ?
                                        input.type === 'select' ?
                                            <select required={ input.required ? true : false } options={input.options} disabled value={cliente.custom && cliente.custom[input.name]}  className="form-control-lg form-control"  name={`${input.name}`}>
                                                <option>Seleccione</option>
                                                {
                                                    input.options?.map( (option,i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                        : 
                                        <select required={ input.required ? true : false } options={input.options} disabled value={cliente.custom && cliente.custom[input.name]} multiple  className="form-control-lg form-control"  name={`${input.name}`}>
                                                {
                                                    input.options?.map( (option,i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                        </select>
                                    : <input type="text" disabled className="form-control-lg form-control"  value={cliente.custom ? cliente.custom[input.name] : ""}  name={input.name} />
                                    
                                }
                                
                            </div>
                        )) : null
                    }
            </div>

            <div className="row mb-5">
                <div className="col-md-4">
                    <Link to="/clients" className="p-2 colorsito">Volver</Link>
                </div>
            </div>
            </div>
    </div>
    )
}
