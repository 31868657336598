import {
    GETTING_LAYOUT,
    GETTING_LAYOUT_SUCCESS,
    GETTING_LAYOUT_ERROR,

    CREATING_LAYOUT,
    CREATING_LAYOUT_SUCCESS,
    CREATING_LAYOUT_ERROR,

    SEARCH_LAYOUT,
    SEARCH_LAYOUT_SUCCESS,
    SEARCH_LAYOUT_ERROR,
    SET_LAYOUT_TO_EDIT,

    TRY_GET_LAYOUTS_TEXT,
    GET_LAYOUTS_TEXT_SUCCESS,
    GET_LAYOUTS_TEXT_ERROR,

    DELETING_LAYOUT,
    DELETING_LAYOUT_SUCCESS,
    DELETING_LAYOUT_ERROR,
}
from '../types';


// cada reducer tiene su propio state

const initialState = {
    plantillas: [],
    newLayout:null,
    layoutEdit:null,
    error:null,
    loading:false,
    plantilla:null
}

export default function (state = initialState, action){
    switch(action.type){
        case GETTING_LAYOUT:
            return {
                ...state,
                loading:true,
                plantillas:[]
            }
        case GETTING_LAYOUT_SUCCESS:
            return {
                ...state,
                loading:false,
                plantillas:action.payload,
                layoutEdit:null,
                plantilla:null
            }
        case GETTING_LAYOUT_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case CREATING_LAYOUT:
            return {
                ...state,
                loading:true
            }
        case CREATING_LAYOUT_SUCCESS:
            return {
                ...state,
                loading:false,
                layoutEdit:action.payload
            }
        case CREATING_LAYOUT_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case SEARCH_LAYOUT:
            return {
                ...state,
                loading:true,
                layoutEdit:null
            }
        case SEARCH_LAYOUT_SUCCESS:
            return {
                ...state,
                loading:false,
                layoutEdit:action.payload
            }
        case SEARCH_LAYOUT_ERROR:
            return {
                ...state,
                loading:false,
                layoutEdit:null
            }
        case SET_LAYOUT_TO_EDIT:
            return {
                ...state,
                layoutEdit:action.payload
            }
        case TRY_GET_LAYOUTS_TEXT:
            return {
                ...state,
                loading:true,
                plantillas:[]
            }
        case GET_LAYOUTS_TEXT_SUCCESS:
            return {
                ...state,
                plantillas:action.payload,
                loading:null,
                error:false
            }
        case GET_LAYOUTS_TEXT_ERROR:
            return {
                ...state,
                plantillas:[],
                loading:null,
                error:action.payload
            }
        case DELETING_LAYOUT:
            return {
                ...state,
                loading:true
            }
        case DELETING_LAYOUT_SUCCESS:
            return {
                ...state,
                loading:false

            }
        case DELETING_LAYOUT_ERROR:
            return {
                ...state,
                loading:false
            }
        default:
            return state;
    }
}