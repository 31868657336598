import React, { useEffect, useState, useRef } from 'react'
// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerClientesAction, borrarCliente, upload_masive_excel_action } from '../../actions/clientesActions';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link  } from 'react-router-dom'
import Swal from 'sweetalert2';
import moment from "moment"
import 'moment/locale/es';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"


import $ from 'jquery'
export const ClientList = () => {
 
    const refFileImport = useRef();
    const dispatch = useDispatch()
    const [getclientes, setGetClientes] = useState(false);
    const [busco, setbusco] = useState(false);

    const clientes = useSelector( state => state.clientes.clientes )
    const loading = useSelector( state => state.clientes.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    const current_user = useSelector( state => state.auth.user )

    // console.log(loading)

    useEffect( async ()=> {
        // consultar la api
        if(!busco){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setbusco(true);
        }
        if(search_custom_page == null){
          $('#loading').hide();
      }
    },[search_custom_page]);



    
    const handleDelete = (client_id) => {
        Swal.fire({
            title: 'Estas Seguro?',
            text: "Estas seguro de borrarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borralo!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(borrarCliente(client_id))
                Swal.fire(
                    'Borrado!',
                    'El cliente fue borrado.',
                    'success'
                )
                await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            }
          })
    }



    const handleImport = () => {
      if(refFileImport.current.files.length){

        dispatch(upload_masive_excel_action(refFileImport.current.files))
      }else{
        Swal.fire({
          icon:"error",
          title:"Ocurrió un error",
          text:"No hemos recibido un archivo, por favor ingresa un archivo con el formato del ejemplo."
        })
      }
    }
    const handleChangePage = async(page)=>{
      await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=${page}&limit=10&size=10`}))
    } 
    const handleSearch = async(e) => {
      // console.log(e.target.value)
      await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${e.target.value}`}))
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de Clientes</span>

                
                {
                  current_user && current_user.roles && current_user.roles.filter( role => role.name == 'administracion').length > 0 ?
                  <button  className="btn btn-primary float-right" data-toggle="modal" data-target="#exampleModal"><span className="fas fa-user-plus "></span>   Carga Masiva</button> : null
                }
                <Link to="/new_client" className="btn btn-primary float-right  mr-2" ><span className="fas fa-plus "></span>   Crear Cliente</Link>
            </div>
          
            
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="table-responsive">
              <div className="row">
                <div className="col-md-4">
                  <input type="text" className="form-control input_busqueda" onChange={handleSearch} placeholder="Buscar [nombre cliente], [correo cliente], [tags], [origen]"/>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th align="right">Contacto</th>
                    <th align="right">Creador</th>
                    <th align="right">Fecha</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    clientes && clientes.records ? clientes.records.map( row =>(
                      <tr>
                        <td>{row.nombre} {row.apellido}</td>
                        <td >{row.contacto ? row.contacto.nombre : "Sin Definir" }</td>
                        <td >{row.creador}</td>
                        <td >{moment(row.fecha).fromNow()}</td>
                        <td >
                          <div className="">
                              <Link to={`/view_client/${row.id}`} className="btn  btn-sm redondo" data-tip="Ver Cliente"><i className="nav-icon fas fa-eye"></i></Link>
                              <span onClick={ () => handleDelete(row.id) } className="btn btn-sm  redondo_rojo" data-tip="Eliminar Cliente"><i className="nav-icon fas fa-remove"></i></span>
                              <Link to={`/edit_client/${row.id}`} className="btn  btn-sm  redondo" data-tip="Editar Cliente"><i className="nav-icon fas fa-edit"></i></Link>
                              <ReactTooltip />
                          </div>
                        </td>
                            
                      </tr>
                    )) : null
                  }
                </tbody>
              </table>
              <div className="text-center">
                  <nav aria-label="Page navigation example">
                      <ul className="pagination">
                          <div className="page-item mr-10">
                              Pág {clientes.currentPage + 1} -

                              {clientes.totalItems} Registros 
                          </div>
                          <li className="page-item"><a className="page-link" onClick={ ()=> handleChangePage( clientes.currentPage>0 ? clientes.currentPage -1 : 0)}>Anterior</a></li>
                          {
                              [...Array(clientes.totalPages).keys()].map( (page,index) => {
                                  if(index < 15){

                                      if(page  == clientes.currentPage ){
                                          return <li className="page-item active">
                                          <a className="page-link" onClick={ ()=> handleChangePage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                          </li>
                                      }else{
                                          return <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(page)} >{page + 1}</a></li>
                                      }
                                  }
                              })
                          }
                          {
                              clientes.totalPages > 15 ? 
                              <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(clientes.totalPages-1)}>{clientes.totalPages-1}</a></li> : null
                          }
                          <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(clientes.currentPage < clientes.totalPages -1 ? clientes.currentPage + 1 : clientes.totalPages -1)}>Siguiente</a></li>
                      </ul>
                  </nav>
              </div>
            </div>
          <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Carga Masiva</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Seleccione un archivo con el formato apropiado</label>
                    <p>Descargar formato <a href="./formato carga masiva.xlsx">aquí</a></p>
                    <input type="file" ref={refFileImport} id="file_masive"/>
                    {
                        loading ? <LoaderSpinner/> : null
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-success" onClick={handleImport}>Importar</button>

                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}
