import {
    TRY_GET_DOMAINS,
    GET_DOMAINS_SUCCESS,
    GET_DOMAINS_ERROR,

    TRY_SAVE_DOMAIN,
    SAVE_DOMAIN_SUCCESS,
    SAVE_DOMAIN_ERROR,

    TRY_VERIFY_DOMAIN,
    VERIFY_DOMAIN_SUCCESS,
    VERIFY_DOMAIN_ERROR
}
from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';


export function get_domains_action( dominio ){
    return async ( dispatch ) => {
        dispatch( try_get_domains() );
        try {
            const respuesta = await clienteAxios.get(`/domain`);
            dispatch(get_domains_success(respuesta.data));
        } catch (error) {
            console.log(error)
            dispatch( get_domains_error() );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const try_get_domains = () => ({
    type:TRY_GET_DOMAINS,
    payload:true
})

const get_domains_success = (data) => ({
    type:GET_DOMAINS_SUCCESS,
    payload:data
})

const get_domains_error = () => ({
    type:GET_DOMAINS_ERROR,
    payload:true   
})

export function save_domain_action( dominio ){
    return async ( dispatch ) => {
        dispatch( try_save_domain() );
        try {
            const respuesta = await clienteAxios.post(`/domain`,dominio);
            dispatch( save_domain_success(respuesta.data) )
            if(respuesta.data.success){

                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:'El dominio se ha registrado correctamente'
                })
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error en el servidor, intenta de nuevo'
                })
            }
        } catch (error) {
            console.log(error)
            dispatch( save_domain_error() );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const try_save_domain = () => ({
    type:TRY_SAVE_DOMAIN,
    payload:true
})

const save_domain_success = () => ({
    type:SAVE_DOMAIN_SUCCESS,
    payload:true
})

const save_domain_error = () => ({
    type:SAVE_DOMAIN_ERROR,
    payload:true   
})


export function verify_domain_action( dominio ){
    return async ( dispatch ) => {
        dispatch( try_verify_domain() );
        try {
            const respuesta = await clienteAxios.post(`/domain/verify`,{dominio});
            dispatch(verify_domain_success(respuesta.data));
            if(respuesta.data.success == true){
                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:'El dominío ha sido verificado'
                })  
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Error',
                    text:'El dominío No ha sido verificado'
                })  
            }
        } catch (error) {
            console.log(error)
            dispatch( verify_domain_error() );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const try_verify_domain = () => ({
    type:TRY_VERIFY_DOMAIN,
    payload:true
})

const verify_domain_success = (data) => ({
    type:VERIFY_DOMAIN_SUCCESS,
    payload:data
})

const verify_domain_error = () => ({
    type:VERIFY_DOMAIN_ERROR,
    payload:true   
})