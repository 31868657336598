import {
    BEGIN_LIST_CONTACTFIELDS,
    LIST_CONTACTFIELDS_SUCCESS,
    LIST_CONTACTFIELDS_ERROR,

    BEGIN_LIST_CLIENTSFIELDS,
    LIST_CLIENTSFIELDS_SUCCESS,
    LIST_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CLIENTSFIELDS,
    UPDATE_CLIENTSFIELDS_SUCCESS,
    UPDATE_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CONTACTSFIELDS,
    UPDATE_CONTACTSFIELDS_SUCCESS,
    UPDATE_CONTACTSFIELDS_ERROR
}
from '../types';


// cada reducer tiene su propio state

const initialState = {
    contactsfields:[],
    clientsfields:[],
    type:null,
    error:null,
    loading:false
}

export default function (state = initialState, action){
    switch(action.type){
        case BEGIN_LIST_CONTACTFIELDS:
            return {
                ...state,
                loading:true,
                fields:[]
            }
        case LIST_CONTACTFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                contactsfields:action.payload
            }  
        case LIST_CONTACTFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                contactsfields:[],
                error:action.payload
            }   

        case BEGIN_LIST_CLIENTSFIELDS:
            return {
                ...state,
                loading:true,
                fields:[]
            }
        case LIST_CLIENTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                clientsfields:action.payload
            }  
        case LIST_CLIENTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                clientsfields:[],
                error:action.payload
            }       
        case BEGIN_UPDATE_CLIENTSFIELDS:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CLIENTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                clientsfields:action.payload
            }
        case UPDATE_CLIENTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

        case BEGIN_UPDATE_CONTACTSFIELDS:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CONTACTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                contactsfields:action.payload
            }
        case UPDATE_CONTACTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }       
        default:
            return state;
    }
}